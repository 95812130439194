import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage'

const usePromotionCode = (): { promotionCode: string; hidePromotionCode: boolean } => {
  const router = useRouter()
  const [promotionCode, setPromotionCode] = useLocalStorage<string>('pmc', undefined)
  const [hidePromotionCode, setHidePromotionCode] = useLocalStorage<boolean>('hpmc', undefined)

  useEffect(() => {
    const pmc = router?.query?.pmc || router?.query?.PMC
    const hpmc = router?.query?.hpmc || router?.query?.HPMC
    if (pmc) setPromotionCode(pmc as string)
    if (hpmc) setHidePromotionCode(hpmc as string === 'true')
  }, [router?.query])

  return { promotionCode, hidePromotionCode }
}

export default usePromotionCode
