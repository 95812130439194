import { useEffect, useState } from 'react'

export const useLocalStorage = <T>(
  identifier: string,
  defaultValue: T = null
): [value: T, setValue: (nextValue: T) => void] => {
  const getInitialValue = () => {
    if (typeof localStorage === 'undefined') return

    const storedValue = localStorage.getItem(identifier)
    if (storedValue != null && storedValue != '') {
      return JSON.parse(storedValue) as T
    }
    return defaultValue
  }

  const [value, setValue] = useState<T>(getInitialValue())

  useEffect(() => {
    if (typeof localStorage === 'undefined') return

    if (value != null) {
      try {
        localStorage.setItem(identifier, JSON.stringify(value))
      } catch (error) {
        console.error('Could not save value to local storage!')
      }
    }
  }, [value])

  return [value, setValue]
}
