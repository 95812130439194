import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import { AstroPayInDTO, AstroPayInRequestDTO } from '@arland-bmnext/webapps-api-data'

export class AstropayPayInPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  executeTransaction(sessionData: SessionData, request: AstroPayInRequestDTO): Promise<AstroPayInDTO> {
    return postJson<AstroPayInRequestDTO, AstroPayInDTO>(
      this.httpClient,
      ['api', 'payment', 'astropay', 'payin'],
      request,
      sessionData
    )
  }
}
