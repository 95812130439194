import { UserSession } from '@arland-bmnext/api-data'
import { TokenDTO, TokenRequestDTO } from '@arland-bmnext/webapps-api-data'
import { getJson, postJson } from './client'
import { HttpClient } from './httpclient'
import { SessionData } from './types'

export class SessionClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async createAuthToken(sessionData: SessionData, request: TokenRequestDTO): Promise<TokenDTO> {
    return await postJson<TokenRequestDTO, TokenDTO>(this.httpClient, ['api', 'auth', 'token'], request, sessionData)
  }

  public async create(sessionData: SessionData, ipAddress: string): Promise<UserSession> {
    const session = await postJson<{}, UserSession>(this.httpClient, ['api', 'session'], {}, sessionData, {
      ip: ipAddress,
    })
    return session
  }

  public async get(sessionData: SessionData): Promise<UserSession> {
    const session = await getJson<UserSession>(this.httpClient, ['api', 'session'], sessionData)
    return session
  }
}
