export const pageview = (url, trackingCode) => {
  if (typeof window?.gtag !== 'undefined') {
    window.gtag('config', trackingCode, {
      page_path: url,
    })
  }
}

export const event = ({ action, category, label, value }) => {
  if (typeof window?.gtag !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}
