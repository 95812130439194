import { EventCard } from '@arland-bmnext/api-data'
import { HttpClient, getJson, SessionData } from '../core'

export class CardsClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getCards(sessionData: SessionData, languageId: number = 1): Promise<EventCard[]> {
    const query = {} as any

    if (languageId) query['langId'] = languageId

    const cards = await getJson<EventCard[]>(this.httpClient, ['api', 'cards'], sessionData, query)

    return cards
  }

  public async getCardById(sessionData: SessionData, cardId: number, languageId: number = 1, marketTypeIds?: string[] | number[]): Promise<EventCard> {
    const query = {} as any

    if (languageId) query['langId'] = languageId
    if (marketTypeIds) query['marketTypeIds'] = marketTypeIds.join(',')

    const card = await getJson<EventCard>(this.httpClient, ['api', 'cards', cardId], sessionData, query)

    return card
  }
}
