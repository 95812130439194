import { changeUserDisplayFormats, isLoggedInCustomer, useUser, useUserDisplayFormats } from '../lib/user'
import { OddsDisplayFormat } from '@arland-bmnext/api-data'
import { useLocalStorage } from './useLocalStorage'

export enum OddValueFormat {
  PotentialWinnings = 'potential-winnings',
  DecimalOdds = 'decimal-odds',
  AmericanOdds = 'american',
  Fractional = 'fractional',
  HongKongOdds = 'hong-kong',
}

export const mapOddsDisplayFormatToOddValueFormat = (format: OddsDisplayFormat) => {
  switch (format) {
    case OddsDisplayFormat.PotentialWinnings:
      return OddValueFormat.PotentialWinnings
    case OddsDisplayFormat.Decimal:
      return OddValueFormat.DecimalOdds
    case OddsDisplayFormat.American:
      return OddValueFormat.AmericanOdds
    case OddsDisplayFormat.HongKong:
      return OddValueFormat.HongKongOdds
    default:
      return null
  }
}

export const mapOddValueFormatToOddsDisplayFormat = (format: OddValueFormat) => {
  switch (format) {
    case OddValueFormat.PotentialWinnings:
      return OddsDisplayFormat.PotentialWinnings
    case OddValueFormat.DecimalOdds:
      return OddsDisplayFormat.Decimal
    case OddValueFormat.AmericanOdds:
      return OddsDisplayFormat.American
    case OddValueFormat.HongKongOdds:
      return OddsDisplayFormat.HongKong
    default:
      return null
  }
}

export const useOddsFormat = (): [
  oddsFormat: OddValueFormat,
  setOddsFormat: (format: OddValueFormat, saveToUserSetting?: boolean) => Promise<void>
] => {
  const { user } = useUser()
  const { displayFormats, mutateUserDisplayFormats } = useUserDisplayFormats(user?.id)
  const isAuthenticated = isLoggedInCustomer(user)

  const [oddsFormat, setOddsFormat] = useLocalStorage<OddValueFormat>('oddsFormat', null)

  const onSetOddsFormat = async (format: OddValueFormat, saveToUserSetting: boolean = false): Promise<void> => {
    const oddDisplayFormat = mapOddValueFormatToOddsDisplayFormat(format)

    if (isAuthenticated && saveToUserSetting) {
      const req = { ...displayFormats, oddsFormat: oddDisplayFormat }
      const res = await changeUserDisplayFormats(req)
      await mutateUserDisplayFormats(res, false)
    }

    setOddsFormat(format)
  }

  return [oddsFormat, onSetOddsFormat]
}
