import { IncomingMessage } from 'http'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus, useContentClient } from './api-client'
import { AxiosClient } from './axiosclient'
import { buildSessionData, Session } from './session'
import {
  AccountSettingsDTO,
  AnalyticsSettingsDTO,
  CasinoCategoriesDTO,
  CasinoSettingsDTO,
  CmsMarketTypeCategoryDTO,
  FrontEndSettingsDTO,
  SportBettingSettingsDTO,
} from '@arland-bmnext/webapps-api-data'
import { FrontEndMarketTypeTranslation, FrontEndResources } from '@bmnext-cms/types/content'
import { useAppContext } from '../context/app.context'

export const useSportsBettingSettings = (): SportBettingSettingsDTO => {
  const { data, error } = useSWR<SportBettingSettingsDTO>('/api/content/sports-betting-settings', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 1000, // 60sec
  })

  handleHttpErrorStatus(error?.status)

  return data
}

export const useBetSharingTextsContent = (
  languageId: number,
): {
  betSharingTextsContent: any
  mutateBetSharingTextsContent: (data?: any, revalidate?: boolean) => Promise<any>
} => {
  const { data, mutate, error } = useSWR<any>(
    languageId ? '/api/content?identifier=Bet-Sharing-Texts&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { betSharingTextsContent: data, mutateBetSharingTextsContent: mutate }
}

export const usePaymentSettings = (): any => {
  const { data, error } = useSWR<any>('/api/content/payment-settings', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 1000, // 60sec
  })

  handleHttpErrorStatus(error?.status)

  return data
}

export const useSidenavSettings = (languageId: number): any => {
  const { data, error } = useSWR<any>(
    languageId ? '/api/content/sidenav-settings?languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 1000, // 60sec
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useFooterContent = (
  languageId: number,
): {
  footerContent: any
  mutateFooterContent: (data?: any, revalidate?: boolean) => Promise<any>
} => {
  const { data, mutate, error } = useSWR<any>(
    languageId ? '/api/content?identifier=Footer&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { footerContent: data, mutateFooterContent: mutate }
}

export const getContentByIdentifier = async (identifier: string, languageId: number): Promise<any> => {
  let query = '?identifier=' + identifier
  if (languageId) query += '&languageId=' + languageId
  const data = await AxiosClient.get<any>(`/api/content${query}`)
  return data
}

export const useQuickMarkets = (languageId: number): CmsMarketTypeCategoryDTO => {
  const { data, error } = useSWR<CmsMarketTypeCategoryDTO[]>(
    languageId ? '/api/content/cms-market-type-categories?gameMode=prematch&type=quick&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data ? data[0] : null
}

export const useRegularMarkets = (languageId: number): CmsMarketTypeCategoryDTO[] => {
  const { data, error } = useSWR<CmsMarketTypeCategoryDTO[]>(
    languageId
      ? '/api/content/cms-market-type-categories?gameMode=prematch&type=regular&languageId=' + languageId
      : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useLiveQuickMarkets = (languageId: number): CmsMarketTypeCategoryDTO => {
  const { data, error } = useSWR<CmsMarketTypeCategoryDTO[]>(
    languageId ? '/api/content/cms-market-type-categories?gameMode=live&type=quick&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data ? data[0] : null
}

export const useLiveRegularMarkets = (languageId: number): CmsMarketTypeCategoryDTO[] => {
  const { data, error } = useSWR<CmsMarketTypeCategoryDTO[]>(
    languageId ? '/api/content/cms-market-type-categories?gameMode=live&type=regular&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useHtmlHeadSection = (): string => {
  const { data, error } = useSWR<{ html: string }>('/api/content/html-head', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000, // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data?.html
}

export const useAnalyticSettings = (): AnalyticsSettingsDTO => {
  const { data, error } = useSWR<AnalyticsSettingsDTO>('/api/content/analytic-settings', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000, // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data
}

// export const useFrontendSettings = (): FrontEndSettingsDTO => {
//   const { data, error } = useSWR<FrontEndSettingsDTO>('/api/content/frontend-settings', defaultFetcher, {
//     revalidateOnFocus: false,
//     revalidateOnReconnect: false,
//     dedupingInterval: 60 * 60 * 1000, // 1h
//   })
//
//   handleHttpErrorStatus(error?.status)
//
//   return data
// }

export const useFrontendSettings = (): FrontEndSettingsDTO => {
  return useAppContext().frontendSettings
}

export const useAccountSettings = (): AccountSettingsDTO => {
  const { data, error } = useSWR<AccountSettingsDTO>('/api/content/account-settings', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000, // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data
}

export const useCasinoSettings = (): CasinoSettingsDTO => {
  const { data, error } = useSWR<CasinoSettingsDTO>('/api/content/casino-settings', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000, // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data
}

export const useCasinoCategories = (languageId: number): CasinoCategoriesDTO[] => {
  const { data, error } = useSWR<CasinoCategoriesDTO[]>(
    languageId ? '/api/content/casino-categories?languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useTheme = (): any[] => {
  const { data, error } = useSWR<any[]>('/api/content/theme', defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60 * 60 * 1000, // 1h
  })

  handleHttpErrorStatus(error?.status)

  return data
}

// export const useFrontendResources = (): any => {
//   const { data, error } = useSWR<any>('/api/content/frontend-resources', defaultFetcher, {
//     revalidateOnFocus: false,
//     revalidateOnReconnect: false,
//     dedupingInterval: 60 * 60 * 1000, // 1h
//   })
//
//   handleHttpErrorStatus(error?.status)
//
//   return data
// }

export const useFrontendResources = (): FrontEndResources => {
  return useAppContext().frontendResources
}

export const useMarketTypeTranslations = (languageId: number): FrontEndMarketTypeTranslation[] => {
  const { data, error } = useSWR<FrontEndMarketTypeTranslation[]>(
    languageId ? '/api/content/cms-market-type-translations?languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 24 * 60 * 60 * 1000, // 24h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useNextLeagueEventWidget = (
  languageId: number,
): {
  nextLeagueEventWidget: any
  mutateNextLeagueEventWidgetContent: (data?: any, revalidate?: boolean) => Promise<any>
} => {
  const { data, mutate, error } = useSWR<any>(
    languageId ? '/api/content?identifier=Next%20Events%20from%20League%20Widget&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { nextLeagueEventWidget: data, mutateNextLeagueEventWidgetContent: mutate }
}

export const useSliderWidget = (languageId: number) => {
  const { data, mutate, error } = useSWR<any>(
    languageId ? '/api/content?identifier=Slider-Widget&languageId=' + languageId : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { sliderWidgetData: data, mutateSliderWidgetData: mutate }
}

export const useLiveApiDomain = (): string => {
  const { data, error } = useSWRImmutable<{ domain: string }>('/api/content/domain-live', defaultFetcher)

  handleHttpErrorStatus(error?.status)

  return data?.domain
}

export const useCdnDomain = (): string => {
  const { data, error } = useSWRImmutable<{ domain: string }>('/api/content/domain-cdn', defaultFetcher)

  handleHttpErrorStatus(error?.status)

  return data?.domain
}

export enum CmsPageIdentifier {
  Home = 'FrontPage',
  Imprint = 'Imprint',
  BettingTerms = 'Betting terms',
  TermsAndConditions = 'TermsAndConditions',
  Help = 'Help',
  Account = 'Account',
  AccountBets = 'Account-Bets',
  AccountBonus = 'Account-Bonus',
  AccountBookings = 'Account-Bookings',
  AccountActivate = 'Account-Activate',
  AccountClose = 'Account-Close',
  AccountTemporaryLock = 'Account-Temporary-Lock',
  AccountChangePersonalData = 'Account-Change-Personal-Data',
  AccountChangeEmail = 'Account-Change-Email',
  AccountChangePhoneNumber = 'Account-Change-Phone-Number',
  AccountChangePassword = 'Account-Change-Password',
  AccountChangePasswordRecovery = 'Account-Password-Recovery',
  AccountResetPassword = 'Account-Reset-Password',
  AccountChangePin = 'Account-Change-Pin',
  AccountDocuments = 'Account-Documents',
  AccountSelflimits = 'Account-Selflimits',
  AccountBetSettings = 'Account-Bet-Settings',
  AccountCommunication = 'Account-Communications',
  Account2FA = 'Account-2FA',
  Favorites = 'Favorites',
  FrontPage = 'FrontPage',
  LandingPage = 'Landing-Page',
  Live = 'Live',
  Onboarding = 'Onboarding',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  Privacy = 'Privacy',
  SportBrowser = 'Sport-Browser',
  SportSearch = 'Sport-Search',
  CardPage = 'Card-Page',
  LeaguePage = 'League-Page',
  EventPage = 'Event-Page',
  AccessDeniedPage = 'Access-Denied-Page',
  Login = 'Login',
  Registration = 'Registration',
  Casino = 'Casino',
}

export const getCmsPage = async (req: IncomingMessage, session: Session, identifier: string) => {
  const contentClient = useContentClient()
  const languageId = session.get('languageId')
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const sessionData = buildSessionData(req, sessionId, token)

  return await contentClient.getContentByIdentifier(sessionData, identifier, languageId)
}

export const getFrontendSettings = async (req: IncomingMessage, session: Session) => {
  const contentClient = useContentClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const sessionData = buildSessionData(req, sessionId, token)

  return await contentClient.getFrontendSettings(sessionData)
}

export const getFrontendResources = async (req: IncomingMessage, session: Session) => {
  const contentClient = useContentClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const sessionData = buildSessionData(req, sessionId, token)

  return await contentClient.getFrontendResources(sessionData)
}

export const getCmsPageData = (
  cmsPage: any
): {
  customHtmlHead?: string
  metaKeywords?: string
  metaTitle?: string
  metaDescription?: string
  body?: string
  helpText?: string
  gameStateDisplayMode?: string
} => {
  const customHtmlHead = cmsPage?.fields?.find((field) => field['identifier'] === 'custom_html_head')?.value?.str
  const metaKeywords = cmsPage?.fields?.find((field) => field['identifier'] === 'meta_keywords')?.value?.str
  const metaTitle = cmsPage?.fields?.find((field) => field['identifier'] === 'meta_title')?.value?.str
  const metaDescription = cmsPage?.fields?.find((field) => field['identifier'] === 'meta_description')?.value?.str
  const body = cmsPage?.fields?.find((field) => field['identifier'] === 'body')?.value?.str
  const helpText = cmsPage?.fields?.find((field) => field['identifier'] === 'help_text')?.value?.str
  const gameStateDisplayMode = cmsPage?.fields?.find((field) => field['identifier'] === 'game_state_display_mode')?.value?.str

  return { customHtmlHead, metaKeywords, metaTitle, metaDescription, body, helpText, gameStateDisplayMode }
}
