export class Constants {
  static AppId: string = 'F6556527-02BC-45D7-BC8E-AD92453E85B6'

  static AvailableLanguages = ['de', 'en', 'br']

  static LeaguesWithFlags = [1595]

  static Head2HeadMarketTypeIds = [496, 497]

  static FingerprintSettings = {
    audio: { timeout: 1000, excludeIOS11: true },
    fonts: { extendedJsFonts: true },
    screen: { detectScreenOrientation: true },
    excludes: {
      userAgent: false,
      webdriver: false,
      language: false,
      colorDepth: false,
      deviceMemory: false,
      pixelRatio: true,
      hardwareConcurrency: false,
      screenResolution: false,
      availableScreenResolution: false,
      timezoneOffset: false,
      timezone: false,
      sessionStorage: false,
      localStorage: false,
      indexedDb: false,
      addBehavior: false,
      openDatabase: false,
      cpuClass: false,
      platform: false,
      doNotTrack: true,
      plugins: false,
      canvas: false,
      webgl: true,
      webglVendorAndRenderer: false,
      adBlock: false,
      hasLiedLanguages: false,
      hasLiedResolution: false,
      hasLiedOs: false,
      hasLiedBrowser: false,
      touchSupport: false,
      fonts: false,
      fontsFlash: true,
      audio: false,
      enumerateDevices: true,
    },
  }
}
