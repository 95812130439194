export const getFirstOrDefault = (list: any[], defaultValue: any = null) => {
  return list != null && list.length > 0 ? list[0] : defaultValue
}

export const moveArrayElement = (list: any[], oldIndex: number, newIndex: number): any[] => {
  if (newIndex >= list.length) {
    var k = newIndex - list.length + 1
    while (k--) {
      list.push(undefined)
    }
  }
  list.splice(newIndex, 0, list.splice(oldIndex, 1)[0])
  return list
}

export type SortDirection = 'asc' | 'desc'
export const sortArray = (array: any[], prop: string, direction: SortDirection) => {
  if (array == null || (array as any) == '' || !Array.isArray(array)) return []
  return array?.sort((a, b) => {
    if (direction === 'asc') {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    } else {
      if (a[prop] > b[prop]) {
        return -1
      }
      if (a[prop] < b[prop]) {
        return 1
      }
      return 0
    }
  })
}

export const upsert = (array: any[], element: any, compareProp: string = 'id') => {
  if (!array) return
  const i = array.findIndex((_element) => _element[compareProp] === element[compareProp])
  if (i > -1) array[i] = element
  else array.push(element)
}

export type GroupedItem = { key: string, values: any[] }
export const groupBy = (array: any[], property: string): GroupedItem[] => {
  const result = []
  array?.forEach(element => {
    const grouping = result.find(item => item.key === element[property])
    if (grouping) {
      grouping.values.push(element)
    } else {
      const newGrouping = { key: element[property], values: [element]}
      result.push(newGrouping)
    }
  })
  return result
}

export const groupByTuple = (array: any[], property1: string, property2: string): GroupedItem[] => {
  const result = []
  array.forEach(element => {
    const grouping = result?.find(item => item.key[property1] === element[property1] && item.key[property2] === element[property2])
    if (grouping) {
      grouping.values.push(element)
    } else {
      const newGrouping = { key: {[property1]: element[property1], [property2]: element[property2]}, values: [element]} 
      result.push(newGrouping)
    }
  })
  return result
}

export const findMaximumProperty = (array: any[], property: string) => {
  return Math.max(...array.map(obj => obj[property]))
}
