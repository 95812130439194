import { Competition, GamingSession } from '@arland-bmnext/api-data'
import { getJson, HttpClient, SessionData } from '../core'

export class GamingClient {
  constructor(private readonly httpClient: HttpClient) {}

  getCasinoGames(sessionData: SessionData): Promise<Competition[]> {
    return getJson<Competition[]>(this.httpClient, ['api', 'gaming', 'casino', 'games'], sessionData)
  }

  playCasinoGame(
    sessionData: SessionData,
    gameId: number,
    languageId: number,
    playmode: 'Real' | 'Demo',
    variant: 'Desktop' | 'Mobile'
  ): Promise<GamingSession> {
    const query = {} as any
    query['playmode'] = playmode
    query['variant'] = variant

    return getJson<GamingSession>(
      this.httpClient,
      ['api', 'gaming', 'casino', 'play', gameId, languageId],
      sessionData,
      query
    )
  }

  getLiveCasinoGames(sessionData: SessionData): Promise<Competition[]> {
    return getJson<Competition[]>(this.httpClient, ['api', 'gaming', 'live', 'casino', 'games'], sessionData)
  }

  playLiveCasinoGame(
    sessionData: SessionData,
    gameId: number,
    languageId: number,
    playmode: 'Real' | 'Demo',
    variant: 'Desktop' | 'Mobile',
    productIdentifier?: string
  ): Promise<GamingSession> {
    const query = {} as any
    query['playmode'] = playmode
    query['variant'] = variant
    if (productIdentifier) query['productIdentifier'] = productIdentifier

    return getJson<GamingSession>(
      this.httpClient,
      ['api', 'gaming', 'live', 'casino', 'play', gameId, languageId],
      sessionData,
      query
    )
  }
}
