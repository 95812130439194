import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import {
  Pay4FunPayOutDTO,
  Pay4FunPayOutProcessRequestDTO,
  Pay4FunPayOutRequestDTO,
  PaymentTransactionDTO,
} from '@arland-bmnext/webapps-api-data'

export class Pay4FunPayOutPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  override processTransaction(sessionData: SessionData, request: Pay4FunPayOutProcessRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<Pay4FunPayOutProcessRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'pay4fun', 'payout', 'process'],
      request,
      sessionData
    )
  }

  executeTransaction(sessionData: SessionData, request: Pay4FunPayOutRequestDTO): Promise<Pay4FunPayOutDTO> {
    return postJson<Pay4FunPayOutRequestDTO, Pay4FunPayOutDTO>(
      this.httpClient,
      ['api', 'payment', 'pay4fun', 'payout'],
      request,
      sessionData
    )
  }
}
