import { BonusAcceptanceHandling, PersonGender, UserIdentificationType } from '@arland-bmnext/api-data'

export type SignInRequest = {
  userIdentificationType: UserIdentificationType
  userName: string
  password: string
  fingerPrint: string
}

export type TwoFARequestModel = {
  token: string
  code: string
  fingerprint: string
}

export type TwoFASettings = {
  mailTFAEveryLogin: boolean
  smstfaEveryLogin: boolean
}

export type RegistrationRequest = {
  logonName?: string
  password?: string
  gender?: PersonGender
  title?: string
  firstName?: string
  lastName?: string
  secondName?: string
  birthName?: string
  birthday?: string
  emailAddresse?: string
  phoneNumber?: string
  languageId?: number
  nationality?: string
  timeZoneId?: number
  passwordRecoveryQuestionId?: number
  passwordRecoveryAnswer?: string
  nationalityCountryId?: string
  birthPlace?: string
  betPin?: string
  cardNumber?: string
  phonePin?: string
  rfidNumber?: string
  currencyId?: number
  promotionCode?: string
  notifySms?: boolean
  sendNewsLetterEmail?: boolean
  bonusAcceptance?: BonusAcceptanceHandling
  externalIdentifier?: string
  streetAddress?: string
  zipCode?: string
  city?: string
  region?: string
  locality?: string
  countryId?: number
  cultureInfoIdentifier?: string
}

export type ChangePasswordRequest = {
  oldPassword?: string
  newPassword?: string
}

export type ChangePinRequest = {
  newPin?: string
}

export enum RequiredUserFields {
  LOGONNAME = 'LogonName',
  BRANCH = 'BranchId',
  PASSWORD = 'Password',
  LANGUAGE = 'LanguageId',
  TIMEZONE = 'TimeZoneId',
  RECOVERYQUESTION = 'PasswordRecoveryQuestionId',
  RECOVERYANSWER = 'PasswordRecoveryAnswer',
  BETPIN = 'BetPin',
  CARDNUMBER = 'CardNumber',
  PHONEPIN = 'PhonePin',
  RFIDNUMBER = 'RFIDNumber',
  EMAIL = 'EMail.Name',
  FIRSTNAME = 'Profile.FirstName',
  SECONDNAME = 'Profile.SecondName',
  INITIAL = 'Profile.Initial',
  LASTNAME = 'Profile.LastName',
  BIRTHNAME = 'Profile.BirthName',
  NATIONALITY = 'Profile.NationalityCountryId',
  BIRTHDAY = 'Profile.Birthday',
  BIRTHPLACE = 'Profile.BirthPlace',
  TITLE = 'Profile.Title',
  GENDER = 'Profile.Gender',
  NATIONALIDENTITYID = 'Profile.NationalIdentityId',
  PHONENUMBER = 'Profile.PhoneNumbers[0].Name',
  PRIMARYADDRESS = 'PrimaryAddress.StreetAddress',
  PRIMARYZIPCODE = 'PrimaryAddress.ZipCode',
  PRIMARYCITY = 'PrimaryAddress.City',
  PRIMARYCOUNTRY = 'PrimaryAddress.CountryId',
  PRIMARYREGION = 'PrimaryAddress.Region',
  PRIMARYLOCALITY = 'PrimaryAddress.Locality',
  CURRENCY = 'MainAccount.CurrencyId',
  VERIFIEDEMAIL = 'EmailVerificationStatus',
  DIVIDER = 'Divider',
}

export enum LockDuration {
  OneDay = 1,
  OneWeek = 2,
  OneMonth = 3,
  ThreeMonths = 4,
}
