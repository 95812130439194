import type {
  DisplayPredicate,
  SessionPredicate,
  PromotionCodePredicate,
  BalancePredicate,
  MainResidenceCountryPredicate,
  AffiliateIdPredicate,
  BTagPredicate,
  TagsPredicate,
  DepositCountPredicate,
} from '@bmnext-cms/types/content/display-filter'
import { Account, User } from '@arland-bmnext/api-data'
import { isLoggedInCustomer } from '../lib/user'

const evalSessionPredicate = (predicate: SessionPredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  return (
    (predicate.value === 'authenticated' && isAuthenticated) ||
    (predicate.value === 'not-authenticated' && !isAuthenticated)
  )
}

const evalPromotionCodePredicate = (predicate: PromotionCodePredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated) return false

  if (predicate.operator === '=') return user.promotionCode === predicate.value
  else if (predicate.operator === '!=') return user.promotionCode !== predicate.value
  else return true
}

const evalBalancePredicate = (predicate: BalancePredicate, user: User, mainAccount: Account): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated || !mainAccount) return false

  if (predicate.operator === '<') return mainAccount.balance < predicate.amount
  else if (predicate.operator === '<=') return mainAccount.balance <= predicate.amount
  else if (predicate.operator === '>') return mainAccount.balance > predicate.amount
  else if (predicate.operator === '>=') return mainAccount.balance >= predicate.amount
  else return true
}

const evalMainResidenceCountryPredicate = (predicate: MainResidenceCountryPredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated) return false
  return user.primaryAddress?.countryId === predicate.countryId
}

const evalAffiliateIdPredicate = (predicate: AffiliateIdPredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated) return false

  if (predicate.operator === '=') return user.affiliatePartner === predicate.value
  else if (predicate.operator === '!=') return user.affiliatePartner !== predicate.value
  else return true
}

const evalBTagPredicate = (predicate: BTagPredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated) return false

  if (predicate.operator === '=') return user.externalIdentifier === predicate.value
  else if (predicate.operator === '!=') return user.externalIdentifier !== predicate.value
  else return true
}

const evalTagsPredicate = (predicate: TagsPredicate, user: User): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated) return false

  if (predicate.operator === 'contains') return user.userTags?.find(tag => tag.tagUniqueId === predicate.value) != null
  else if (predicate.operator === 'contains-not') return user.userTags?.find(tag => tag.tagUniqueId === predicate.value) == null
  else return true
}

const evalDepositCountPredicate = (predicate: DepositCountPredicate, user: User, mainAccount: Account): boolean => {
  const isAuthenticated = isLoggedInCustomer(user)
  if (!isAuthenticated || !mainAccount) return false

  if (predicate.operator === '<') return mainAccount.depositCount < predicate.value
  else if (predicate.operator === '<=') return mainAccount.depositCount <= predicate.value
  else if (predicate.operator === '>') return mainAccount.depositCount > predicate.value
  else if (predicate.operator === '>=') return mainAccount.depositCount >= predicate.value
  else return true
}

const evalDisplayPredicate = (predicate: DisplayPredicate, user: User, mainAccount: Account): boolean => {
  switch (predicate.type) {
    case 'session':
      return evalSessionPredicate(predicate, user)
    case 'promotion-code':
      return evalPromotionCodePredicate(predicate, user)
    case 'balance':
      return evalBalancePredicate(predicate, user, mainAccount)
    case 'main-residence-country':
      return evalMainResidenceCountryPredicate(predicate, user)
    case 'affiliate-id':
      return evalAffiliateIdPredicate(predicate, user)
    case 'ia-btag':
      return evalBTagPredicate(predicate, user)
    case 'tags':
      return evalTagsPredicate(predicate, user)
    case 'deposit-count':
      return evalDepositCountPredicate(predicate, user, mainAccount)
    default:
      return true
  }
}

export const evalDisplayPredicates = (predicates: DisplayPredicate[], user: User, mainAccount: Account): boolean => {
  if (predicates == null || predicates.length === 0) return true

  let res = true
  predicates.forEach((predicate) => {
    res = res && evalDisplayPredicate(predicate, user, mainAccount)
  })

  return res
}
