import { PaymentTransactionTypeEnum } from '@arland-bmnext/api-data'
import { getJson, HttpClient, postJson, SessionData } from '../core'
import { PaybrokersPayInPaymentHandlerClient } from './handler/paybrokers/paybrokers-payin-handler'
import { InitialTransactionRequestDTO, PaymentMethodResultDTO, PaymentStatusDTO, PaymentStatusRequestDTO, PaymentTransactionDTO, ProcessTransactionRequestDTO } from '@arland-bmnext/webapps-api-data'
import { PaybrokersPayOutPaymentHandlerClient } from './handler/paybrokers/paybrokers-payout-handler'
import { ApayPayInPaymentHandlerClient } from './handler/apay/apay-payin-handler'
import { ApayPayOutPaymentHandlerClient } from './handler/apay/apay-payout-handler'
import { Pay4FunPayInPaymentHandlerClient } from './handler/pay4fun/pay4fun-payin-handler'
import { Pay4FunPayOutPaymentHandlerClient } from './handler/pay4fun/pay4fun-payout-handler'
import { ApcoPayInPaymentHandlerClient } from './handler/apco/apco-payin-handler'
import { ApcoPayOutPaymentHandlerClient } from './handler/apco/apco-payout-handler'
import { AstropayPayInPaymentHandlerClient } from './handler/astropay/astropay-payin-handler'
import { AstropayPayOutPaymentHandlerClient } from './handler/astropay/astropay-payout-handler'

export class PaymentClient {
  public readonly paybrokersPayInHandler: PaybrokersPayInPaymentHandlerClient
  public readonly paybrokersPayOutHandler: PaybrokersPayOutPaymentHandlerClient
  public readonly apayPayInHandler: ApayPayInPaymentHandlerClient
  public readonly apayPayOutHandler: ApayPayOutPaymentHandlerClient
  public readonly pay4FunPayInHandler: Pay4FunPayInPaymentHandlerClient
  public readonly pay4FunPayOutHandler: Pay4FunPayOutPaymentHandlerClient
  public readonly apcoPayInHandler: ApcoPayInPaymentHandlerClient
  public readonly apcoPayOutHandler: ApcoPayOutPaymentHandlerClient
  public readonly astropayPayInHandler: AstropayPayInPaymentHandlerClient
  public readonly astropayPayOutHandler: AstropayPayOutPaymentHandlerClient

  constructor(private readonly httpClient: HttpClient) {
    this.paybrokersPayInHandler = new PaybrokersPayInPaymentHandlerClient(httpClient)
    this.paybrokersPayOutHandler = new PaybrokersPayOutPaymentHandlerClient(httpClient)
    this.apayPayInHandler = new ApayPayInPaymentHandlerClient(httpClient)
    this.apayPayOutHandler = new ApayPayOutPaymentHandlerClient(httpClient)
    this.pay4FunPayInHandler = new Pay4FunPayInPaymentHandlerClient(httpClient)
    this.pay4FunPayOutHandler = new Pay4FunPayOutPaymentHandlerClient(httpClient)
    this.apcoPayInHandler = new ApcoPayInPaymentHandlerClient(httpClient)
    this.apcoPayOutHandler = new ApcoPayOutPaymentHandlerClient(httpClient)
    this.astropayPayInHandler = new AstropayPayInPaymentHandlerClient(httpClient)
    this.astropayPayOutHandler = new AstropayPayOutPaymentHandlerClient(httpClient)
  }

  getPaymentHandlers(
    sessionData: SessionData,
    type: PaymentTransactionTypeEnum,
    languageId: number
  ): Promise<PaymentMethodResultDTO> {
    const query = {
      type,
      languageId,
    } as any

    return getJson<PaymentMethodResultDTO>(this.httpClient, ['api', 'payment', 'methods'], sessionData, query)
  }

  initTransaction(sessionData: SessionData, request: InitialTransactionRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<InitialTransactionRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'init'],
      request,
      sessionData
    )
  }

  processTransaction(sessionData: SessionData, request: ProcessTransactionRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<ProcessTransactionRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'process'],
      request,
      sessionData
    )
  }

  getPaymentTransactionStatus(sessionData: SessionData, request: PaymentStatusRequestDTO): Promise<PaymentStatusDTO> {
    return postJson<PaymentStatusRequestDTO, PaymentStatusDTO>(this.httpClient, ['api', 'payment', 'status'], request, sessionData)
  }
}
