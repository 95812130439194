import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import { ClientInit, ClientResponse, HttpClient, HttpQuery } from './httpclient'
import { toErrorStatus } from './status'
import { SessionData } from './types'

export class AxiosHttpClient implements HttpClient {
  private readonly axiosInstance: AxiosInstance

  constructor(public readonly init: ClientInit, axiosInstance?: AxiosInstance) {
    this.axiosInstance = axiosInstance || axios.create()
    this.axiosInstance.defaults.withCredentials = false
  }

  async postJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionData?: SessionData,
    query?: HttpQuery,
  ): Promise<ClientResponse<Result>> {
    const axiosRequest = this.buildPostJsonRequest(relativeUrl, request, sessionData, query)
    const axiosResponse = await this.axiosInstance(axiosRequest)

    return toClientResponse<Result>(axiosResponse)
  }

  async postFormDataJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionData?: SessionData,
    query?: HttpQuery,
  ): Promise<ClientResponse<Result>> {
    const axiosRequest = this.buildPostJsonRequest(relativeUrl, request, sessionData, query, true)
    axiosRequest.headers['content-type'] = 'multipart/form-data'
    const axiosResponse = await this.axiosInstance(axiosRequest)

    return toClientResponse<Result>(axiosResponse)
  }

  async putJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionData?: SessionData,
    query?: HttpQuery
  ): Promise<ClientResponse<Result>> {
    const axiosRequest = this.buildPutJsonRequest(relativeUrl, request, sessionData, query)
    const axiosResponse = await this.axiosInstance(axiosRequest)

    return toClientResponse<Result>(axiosResponse)
  }

  async deleteJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionData?: SessionData,
    query?: HttpQuery
  ): Promise<ClientResponse<Result>> {
    const axiosRequest = this.buildDeleteJsonRequest(relativeUrl, request, sessionData, query)
    const axiosResponse = await this.axiosInstance(axiosRequest)

    return toClientResponse<Result>(axiosResponse)
  }

  async getJson<Result>(
    relativeUrl: string,
    sessionData?: SessionData,
    query?: HttpQuery
  ): Promise<ClientResponse<Result>> {
    const axiosRequest = this.buildGetJsonRequest(relativeUrl, sessionData, query)
    const axiosResponse = await this.axiosInstance(axiosRequest)

    return toClientResponse<Result>(axiosResponse)
  }

  protected buildPostJsonRequest(
    relativeUrl: string,
    json: any,
    sessionData: SessionData | undefined,
    query: HttpQuery | undefined,
    useExternalBaseUrl: boolean = false
  ): AxiosRequestConfig {
    const url = this.buildUrl(relativeUrl, useExternalBaseUrl)
    return {
      url: url,
      params: query,
      headers: buildHeader(sessionData, this.init.application.guid),
      paramsSerializer: undefined,
      method: 'post',
      data: json,
    }
  }

  protected buildPutJsonRequest(
    relativeUrl: string,
    json: any,
    sessionData: SessionData | undefined,
    query: HttpQuery | undefined
  ): AxiosRequestConfig {
    const url = this.buildUrl(relativeUrl)
    return {
      url: url,
      params: query,
      headers: buildHeader(sessionData, this.init.application.guid),
      paramsSerializer: undefined,
      method: 'put',
      data: json,
    }
  }

  protected buildDeleteJsonRequest(
    relativeUrl: string,
    json: any,
    sessionData: SessionData | undefined,
    query: HttpQuery | undefined
  ): AxiosRequestConfig {
    const url = this.buildUrl(relativeUrl)
    return {
      url: url,
      params: query,
      headers: buildHeader(sessionData, this.init.application.guid),
      paramsSerializer: undefined,
      method: 'delete',
      data: json,
    }
  }

  protected buildGetJsonRequest(
    relativeUrl: string,
    sessionData: SessionData | undefined,
    query: HttpQuery | undefined
  ): AxiosRequestConfig {
    const url = this.buildUrl(relativeUrl)
    return {
      url: url,
      headers: buildHeader(sessionData, this.init.application.guid),
      params: query,
      paramsSerializer: undefined,
      method: 'get',
    }
  }

  protected buildUrl(relativeUrl: string, useExternalBaseUrl: boolean = false): string {
    const baseUrl = useExternalBaseUrl ? this.init.externalBaseUrl : this.init.baseUrl

    return baseUrl.endsWith('/')
      ? `${baseUrl}${relativeUrl}`
      : `${baseUrl}/${relativeUrl}`
  }
}

function buildHeader(sessionData: SessionData | undefined, appId: string): Record<string, string> | undefined {
  const header = {} as any
  if (sessionData) {
    if (sessionData.token) header['Authorization'] = `Bearer ${sessionData.token.token}`
    if (sessionData.sessionId) header['session'] = sessionData.sessionId
    if (sessionData.host) header["'host'"] = sessionData.host
    if (sessionData.origin) header['origin'] = sessionData.origin
    if (sessionData.useragent) header['user-agent'] = sessionData.useragent
    if (sessionData.ipAddress) header['x-user-ip'] = sessionData.ipAddress
  }
  header['appid'] = appId
  return header
}

function toClientResponse<T>(response: AxiosResponse): ClientResponse<T> {
  const errorStatus = toErrorStatus(response?.status)

  if (errorStatus == undefined) {
    return {
      status: 'ok',
      body: response?.data,
    }
  }

  return {
    status: errorStatus,
    statusCode: response?.status,
    statusText: response?.statusText,
  }
}
