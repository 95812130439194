import useSWR, { KeyedMutator, mutate } from 'swr'
import {
  Account,
  AccountType,
  Bet,
  BetSlipState,
  CalculateCashoutResult,
  User,
  UserDefinedBetSettings,
  UserDefinedMarketingSettings,
  UserDisplayFormatPreference,
  UserIdentificationType,
  UserLogonResult,
  UserResult,
  UserType,
  ValidateTFAResult,
} from '@arland-bmnext/api-data'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus, useAccountClient } from './api-client'
import { AxiosClient } from './axiosclient'
import { CommunicationSettingsDTO, FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import { sortArray } from '../util/array'
import Router from 'next/router'
import { Constants } from '../util/constants'
import { getNodeLanguages } from './account'
import { RegistrationRequest, SignInRequest, TwoFARequestModel } from './api-client/account'
import { PagedResult } from './api-client/core'
import { SaveUserFavoritesOrderRequestModel } from './api-client/odds/model'
import { buildSessionData, Session } from './session'
import { IncomingMessage } from 'http'

export const useUser = (): {
  user: User
  mutateUser: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateUser,
  } = useSWR<User>('/api/account/user', defaultFetcher, { dedupingInterval: 60000 })

  handleHttpErrorStatus(error?.status)

  return {
    user: data,
    mutateUser,
  }
}

export const getUser = async (req: IncomingMessage, session: Session) => {
  const accountClient = useAccountClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const sessionData = buildSessionData(req, sessionId, token)

  return await accountClient.getUserProfile(sessionData)
}

export const getUserAccounts = async (req: IncomingMessage, session: Session, userId: number) => {
  const accountClient = useAccountClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const sessionData = buildSessionData(req, sessionId, token)

  return await accountClient.getUserAccounts(sessionData, userId)
}

export const useUserCommunicationSettings = (): {
  communicationSettings: CommunicationSettingsDTO
  mutateCommunicationSettings: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateCommunicationSettings,
  } = useSWR<CommunicationSettingsDTO>('/api/account/communication-settings', defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  return {
    communicationSettings: data,
    mutateCommunicationSettings,
  }
}

export const changeUserCommunicationSettings = async (
  communicationSettings: CommunicationSettingsDTO,
): Promise<CommunicationSettingsDTO> => {
  return AxiosClient.put<CommunicationSettingsDTO, CommunicationSettingsDTO>(
    '/api/account/change-communication-settings',
    communicationSettings,
  )
}

export const getUserByIdentificationTypes = async (
  identity: string,
  identificationTypes: UserIdentificationType[],
): Promise<User> => {
  let query = '?'
  query += 'identity=' + identity
  query += '&identificationTypes=' + identificationTypes.join(',')
  const data = await AxiosClient.get<User>(`/api/account/user-by-identity${query}`)
  return data
}

export const useUserAccounts = (): {
  mainAccount: Account
  freebetAccount: Account
  freebetTokenAccount: Account
  mutateUserAccounts: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateUserAccounts,
  } = useSWR<Account[]>('/api/account/user-accounts', defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  let mainAccount: Account = null
  let freebetAccount: Account = null
  let freebetTokenAccount: Account = null

  if (data != null && data?.length > 0) {
    mainAccount = data?.find((x) => x.type === AccountType.Main)
    freebetAccount = data?.find((x) => x.type === AccountType.FreeBet)
    freebetTokenAccount = data?.find((x) => x.type === (8 as any))
  }

  return {
    mainAccount,
    freebetAccount,
    freebetTokenAccount,
    mutateUserAccounts,
  }
}

const openUserBetsRoute = (cashoutEnabled: boolean, pageSize?: number, includeDetails?: boolean) =>
  `/api/account/user-bets-with-cashout?startIndex=0&pageSize=${pageSize ?? 5}&betStates=${BetSlipState.Open}&cashoutEnabled=${cashoutEnabled}&includeDetails=${includeDetails ?? false}`
export const mutateOpenUserBets = async (cashoutEnabled: boolean, pageSize?: number) =>
  await mutate(openUserBetsRoute(cashoutEnabled, pageSize))
export const useOpenUserBets = (
  cashoutEnabled: boolean = false,
  pageSize?: number,
  includeDetails?: boolean,
): {
  openBets: { userBet: Bet; cashoutCalculation: CalculateCashoutResult }[]
  mutateOpenBets: (data?: any, shouldRevalidate?: boolean) => Promise<any>
  isValidating: boolean
} => {
  const {
    data,
    error,
    mutate: mutateOpenBets,
    isValidating,
  } = useSWR<{ userBet: Bet; cashoutCalculation: CalculateCashoutResult }[]>(
    openUserBetsRoute(cashoutEnabled, pageSize, includeDetails),
    defaultFetcher,
    {
      refreshInterval: 20000,
      dedupingInterval: 10000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  handleHttpErrorStatus(error?.status)

  return {
    openBets: data,
    mutateOpenBets,
    isValidating,
  }
}

export const useUserBetSettings = (): {
  betSettings: UserDefinedBetSettings
  mutateUserBetSettings: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateUserBetSettings,
  } = useSWR<UserDefinedBetSettings>('/api/account/user-bet-settings', defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  return {
    betSettings: data,
    mutateUserBetSettings,
  }
}

export const useUserDisplayFormats = (
  userId: number,
): {
  displayFormats: UserDisplayFormatPreference
  mutateUserDisplayFormats: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateUserDisplayFormats,
  } = useSWR<UserDisplayFormatPreference>(
    userId ? `/api/account/user-display-formats?userId=${userId}` : null,
    defaultFetcher,
    {
      dedupingInterval: 60000,
    },
  )

  handleHttpErrorStatus(error?.status)

  return {
    displayFormats: data,
    mutateUserDisplayFormats,
  }
}

export const changeUserDisplayFormats = async (
  request: UserDisplayFormatPreference,
): Promise<UserDisplayFormatPreference> => {
  const data = await AxiosClient.put<UserDisplayFormatPreference, UserDisplayFormatPreference>(
    `/api/account/change-display-formats`,
    request,
  )
  return data
}

export const useUserMarketingSettings = (): {
  marketingSettings: UserDefinedMarketingSettings
  mutateUserMarketingSettings: KeyedMutator<UserDefinedMarketingSettings>
} => {
  const {
    data,
    error,
    mutate: mutateUserMarketingSettings,
  } = useSWR<UserDefinedMarketingSettings>('/api/account/user-marketing-settings', defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  return {
    marketingSettings: data,
    mutateUserMarketingSettings,
  }
}

const buildLoginRequest = (
  userIdentificationType: UserIdentificationType,
  identifier: string,
  password: string,
  fingerPrint: string = '',
) => {
  const request: SignInRequest = {
    userIdentificationType,
    userName: identifier,
    password,
    fingerPrint,
  }
  return request
}

export const signInUser = async (
  userIdentificationType: UserIdentificationType,
  userName: string,
  password: string,
  fingerPrint: string = '',
): Promise<UserLogonResult> => {
  const request = buildLoginRequest(userIdentificationType, userName, password, fingerPrint)
  const data: UserLogonResult = await AxiosClient.post('/api/account/login', request)
  return data
}

export const signInTfaUser = async (
  tfaToken: string,
  tfaCode: string,
  fingerprint: string = '',
): Promise<ValidateTFAResult> => {
  const request: TwoFARequestModel = { token: tfaToken, code: tfaCode, fingerprint: fingerprint }
  const data = await AxiosClient.post<TwoFARequestModel, ValidateTFAResult>('/api/account/login-tfa', request)
  return data
}

export const signOutUser = async (): Promise<User> => {
  const data = await AxiosClient.post<null, User>('/api/account/logout', null)
  return data
}

type RegisterResult = UserResult & { isCpfValid?: boolean }
export const registerUser = async (request: RegistrationRequest): Promise<RegisterResult> => {
  const data = await AxiosClient.post<RegistrationRequest, UserResult>('/api/account/register', request)
  return data
}

export const changeSessionLanguage = async (languageId: number, locale: string): Promise<void> => {
  await AxiosClient.post('/api/account/change-language', { languageId, locale, savePreference: false })
}

export const changeUserLanguage = async (languageId: number, locale: string): Promise<void> => {
  await AxiosClient.post('/api/account/change-language', { languageId, locale, savePreference: true })
}

export const isAnonymousUser = (user: User): boolean => {
  if (user == null) return true
  return user?.type === UserType.Anonymous
}

export const isLoggedInCustomer = (user: User): boolean => {
  if (user == null) return false
  return user?.type === UserType.Customer
}

export const getCurrencyIdForUser = (user: User, mainAccount: Account): number => {
  if (user == null && mainAccount == null) return null
  return isAnonymousUser(user) ? user?.branch?.defaultCurrencyId : mainAccount?.currencyId
}

export const isUserIdentifierUnique = async (type: UserIdentificationType, identifier: string): Promise<boolean> => {
  const data = await AxiosClient.post<any, boolean>('/api/account/is-user-identifier-unique', { type, identifier })
  return data
}

export const getUserBets = async (
  startIndex: number = 0,
  pageSize: number = 15,
  betStates?: BetSlipState[],
  includeDetails?: boolean,
  orderBy: string = 'id desc',
): Promise<PagedResult<Bet>> => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&orderBy=' + orderBy
  query += '&includeDetails=' + (includeDetails ? 'true' : 'false')
  if (betStates) query += '&betStates=' + betStates.join(',')
  const data = await AxiosClient.get<PagedResult<Bet>>(`/api/account/user-bets${query}`)
  return data
}

export const getUserBetById = async (id: number, languageId: number): Promise<Bet> => {
  let query = '?'
  query += 'betId=' + id
  query += '&languageId=' + languageId
  const data = await AxiosClient.get<Bet>(`/api/account/user-bet${query}`)
  return data
}

export const addUserFavoriteLeague = async (leagueId: number): Promise<boolean> => {
  const data = await AxiosClient.post<any, boolean>('/api/user-favorites/add', { leagueId })
  return data
}

export const removeUserFavoriteLeague = async (leagueId: number): Promise<boolean> => {
  const data = await AxiosClient.post<any, boolean>('/api/user-favorites/remove', { leagueId })
  return data
}

export const saveUserFavoritesOrder = async (request: SaveUserFavoritesOrderRequestModel[]): Promise<any[]> => {
  const data = await AxiosClient.post<SaveUserFavoritesOrderRequestModel[], any[]>('/api/user-favorites/save', request)
  return data
}

export const useUserFavoriteLeagues = (
  languageId: number,
): {
  userFavoriteLeagues: FavoriteLeagueDTO[]
  mutateUserFavoriteLeagues: KeyedMutator<FavoriteLeagueDTO[]>
  isValidating: boolean
} => {
  let query = '?languageId=' + languageId

  const {
    data,
    error,
    isValidating,
    mutate: mutateUserFavoriteLeagues,
  } = useSWR<FavoriteLeagueDTO[]>(languageId ? '/api/user-favorites/get' + query : null, defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  return {
    userFavoriteLeagues: data ? sortArray(data, 'userFavoriteSortKey', 'desc') : null,
    mutateUserFavoriteLeagues,
    isValidating,
  }
}

export const redirectAfterSuccessfulLogin = async (user: User, locale: string, redirectTo?: string) => {
  const nodeLanguages = await getNodeLanguages()

  const language = nodeLanguages?.languages?.find((lang) => lang.id === user.languageId)
  const destination = redirectTo != null ? (redirectTo as string) : '/sports'

  // if user language is different to current locale, redirect to user langauge if available
  if (language != null && language.shortSign != locale && Constants.AvailableLanguages.includes(language.shortSign)) {
    return Router.push(destination, undefined, { locale: language.shortSign })
  }

  // fallback to default node language, if user language is not available
  const defaultLang = nodeLanguages?.languages?.find((lang) => lang.id === nodeLanguages.defaultLanguageId)
  if (defaultLang) {
    await changeSessionLanguage(defaultLang.id, defaultLang.shortSign)
    return Router.push(destination, undefined, { locale: defaultLang.shortSign })
  }

  // should not happen
  Router.push(destination)
}
