import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import {
  PaymentTransactionDTO,
  ProcessTransactionRequestDTO,
} from '@arland-bmnext/webapps-api-data'

export class AstropayPayOutPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  override processTransaction(sessionData: SessionData, request: ProcessTransactionRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<ProcessTransactionRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'astro', 'payout', 'process'],
      request,
      sessionData
    )
  }

  executeTransaction(sessionData: SessionData, request: ProcessTransactionRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<ProcessTransactionRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'astro', 'payout'],
      request,
      sessionData
    )
  }
}
