import { IncomingMessage } from 'http'
import { PageFilter, PageFilterResult, PageFilterType } from './types'
import { mustChangePasswordFilter } from './must-change-password'
import { mustCompleteMissingFieldsFilter } from './must-complete-missing-fields'
import { mustResetPasswordFilter } from './must-reset-password'
import { pageIsInactiveFilter } from './page-is-inactive'
import { pageRequiresAuthenticationFilter } from './page-requires-authentication'
import { websiteRequiresAuthenticationFilter } from './website-requires-authentication'
import { websiteIsDisabledFilter } from './website-is-disabled'
import { FrontEndSettingsDTO } from '@arland-bmnext/webapps-api-data'

export const handlePageFilter = async (
  req: IncomingMessage,
  locale: string,
  exemptedFilterTypes: PageFilterType[],
  cmsPage: any | null,
  frontendSettings: FrontEndSettingsDTO,
): Promise<PageFilterResult> => {
  let filterTypes = [
    PageFilterType.WebsiteIsDisabled,
    PageFilterType.PageIsInactive,
    PageFilterType.WebsiteRequiresAuthentication,
    PageFilterType.PageRequiresAuthentication,
    PageFilterType.MustChangePassword,
    PageFilterType.MustResetPassword,
    PageFilterType.MustCompleteMissingFields,
  ]

  filterTypes = filterTypes.filter((filter) => !exemptedFilterTypes.includes(filter))

  let res: PageFilterResult = null

  for (let filterType of filterTypes) {
    const filter: PageFilter = getPageFilterForType(filterType)
    if (filter) {
      res = await filter(req, cmsPage, frontendSettings)

      if (res.notFound) {
        return res
      }

      if (res.redirect?.destination) {
        res.redirect.destination = `/${locale}${res.redirect.destination}`
        return res
      }
    }
  }

  return res
}

const getPageFilterForType = (pageFilterType: PageFilterType): PageFilter => {
  switch (pageFilterType) {
    case PageFilterType.WebsiteIsDisabled:
      return websiteIsDisabledFilter
    case PageFilterType.WebsiteRequiresAuthentication:
      return websiteRequiresAuthenticationFilter
    case PageFilterType.PageRequiresAuthentication:
      return pageRequiresAuthenticationFilter
    case PageFilterType.PageIsInactive:
      return pageIsInactiveFilter
    case PageFilterType.MustChangePassword:
      return mustChangePasswordFilter
    case PageFilterType.MustResetPassword:
      return mustResetPasswordFilter
    case PageFilterType.MustCompleteMissingFields:
      return mustCompleteMissingFieldsFilter
    default:
      return null
  }
}
