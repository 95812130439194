import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { handleHttpErrorStatus } from './api-client'

export class AxiosClient {
  public static axiosInstance: AxiosInstance = axios.create()

  constructor() {
    AxiosClient.axiosInstance.interceptors.response.use(this.handleSuccess, this.handleError)
    AxiosClient.axiosInstance.defaults.timeout = 60000
  }

  public static async get<TResponse>(path): Promise<TResponse> {
    const res = await this.axiosInstance.get<TResponse>(path)
    return res.data
  }

  public static async put<TRequest, TResponse>(path, payload): Promise<TResponse> {
    const res = await this.axiosInstance.put<TRequest, AxiosResponse<TResponse, any>, any>(path, payload)
    return res.data
  }

  public static async post<TRequest, TResponse>(path, payload): Promise<TResponse> {
    const res = await this.axiosInstance.post<TRequest, AxiosResponse<TResponse, any>, any>(path, payload)
    return res.data
  }

  public static async upload(path, payload) {
    const res = await this.axiosInstance.post(path, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    return res.data
  }

  public static async delete<TRequest, TResponse>(path, payload): Promise<TResponse> {
    const res = await this.axiosInstance.delete<TRequest, AxiosResponse<TResponse, any>, any>(path, payload)
    return res.data
  }

  private handleSuccess(response: AxiosResponse<any, any>) {
    return response
  }

  private handleError = (error: any) => {
    if (error?.response?.status == null) {
      console.error('Error from http request', error)
    }

    handleHttpErrorStatus(error?.response?.status)

    return Promise.reject(error)
  }
}

export default new AxiosClient()
