import {
  BetBuilderBetSlipItem,
  CalculateCashoutResult,
  CashoutRequest,
  CashoutSubmitResult,
  CheckBetBonusRequest,
  CheckBetResult,
  CheckBetSlipResult,
  Market,
  PrepareBetsRequest,
  PreparedBets,
  SubmitBetSlipResult,
  TestNewBetItemRequest,
  TestNewBetSlipItemResult,
  ValidTokenBonusRequest,
  ValidTokenBonusResult
} from '@arland-bmnext/api-data'
import { getJson, HttpClient, postJson, SessionData } from '../core'
import { CheckBetSlipRequest, CheckBetSlipsRequest, PlaceBetSlipRequest, PlaceBetSlipsRequest } from '.'
import { TopWinnerDTO } from '@arland-bmnext/webapps-api-data'

export class BetsClient {
  constructor(private readonly httpClient: HttpClient) {
  }

  testNewBetItem(sessionData: SessionData, request: TestNewBetItemRequest): Promise<TestNewBetSlipItemResult> {
    return postJson<TestNewBetItemRequest, TestNewBetSlipItemResult>(
      this.httpClient,
      ['api', 'bet', 'testbet'],
      request,
      sessionData
    )
  }

  checkBet(sessionData: SessionData, request: CheckBetSlipRequest): Promise<CheckBetSlipResult> {
    return postJson<CheckBetSlipRequest, CheckBetSlipResult>(
      this.httpClient,
      ['api', 'bet', 'checkbet'],
      request,
      sessionData
    )
  }

  checkBets(sessionData: SessionData, request: CheckBetSlipsRequest): Promise<CheckBetSlipResult[]> {
    return postJson<CheckBetSlipsRequest, CheckBetSlipResult[]>(
      this.httpClient,
      ['api', 'bet', 'checkbets'],
      request,
      sessionData
    )
  }

  placeBet(sessionData: SessionData, request: PlaceBetSlipRequest): Promise<SubmitBetSlipResult> {
    return postJson<PlaceBetSlipRequest, SubmitBetSlipResult>(
      this.httpClient,
      ['api', 'bet', 'placebet'],
      request,
      sessionData
    )
  }

  placeBets(sessionData: SessionData, request: PlaceBetSlipsRequest): Promise<SubmitBetSlipResult> {
    return postJson<PlaceBetSlipsRequest, SubmitBetSlipResult>(
      this.httpClient,
      ['api', 'bet', 'placebets'],
      request,
      sessionData
    )
  }

  calculateCashout(sessionData: SessionData, betId: number): Promise<CalculateCashoutResult> {
    return getJson<CalculateCashoutResult>(this.httpClient, ['api', 'bet', 'calculatecashout', betId], sessionData)
  }

  cashoutBet(sessionData: SessionData, betId: number, request: CashoutRequest): Promise<CashoutSubmitResult> {
    return postJson<CashoutRequest, CashoutSubmitResult>(
      this.httpClient,
      ['api', 'bet', 'cashout', betId],
      request,
      sessionData
    )
  }

  getCashoutState(sessionData: SessionData, submitId: string): Promise<CashoutSubmitResult> {
    return getJson<CashoutSubmitResult>(this.httpClient, ['api', 'bet', 'cashoutstate', submitId], sessionData)
  }

  getBetState(sessionData: SessionData, submitId: string): Promise<SubmitBetSlipResult> {
    return getJson<SubmitBetSlipResult>(this.httpClient, ['api', 'bet', 'state', submitId], sessionData)
  }

  getValidTokenBonuses(sessionData: SessionData, request: ValidTokenBonusRequest[]): Promise<ValidTokenBonusResult[]> {
    return postJson<ValidTokenBonusRequest[], ValidTokenBonusResult[]>(
      this.httpClient,
      ['api', 'bet', 'validtokenbonuses'],
      request,
      sessionData
    )
  }

  checkBetsWithBonuses(
    sessionData: SessionData,
    request: (CheckBetSlipRequest & CheckBetBonusRequest)[]
  ): Promise<CheckBetResult[]> {
    return postJson<(CheckBetSlipRequest & CheckBetBonusRequest)[], CheckBetResult[]>(
      this.httpClient,
      ['api', 'bet', 'checkbetswithbonuses'],
      request,
      sessionData
    )
  }

  getBetBuiilderFixtureId(sessionData: SessionData, gameId: number): Promise<number> {
    return getJson<number>(this.httpClient, ['api', 'betbuilder', 'fixture', gameId], sessionData)
  }

  getBetBuiilderBetSlipItem(sessionData: SessionData, betSlipUid: number): Promise<BetBuilderBetSlipItem> {
    return getJson<BetBuilderBetSlipItem>(this.httpClient, ['api', 'betbuilder', 'betslipitem', betSlipUid], sessionData)
  }

  getBetBuiilderMarket(sessionData: SessionData, marketId: number, oddId: number): Promise<Market> {
    return getJson<Market>(this.httpClient, ['api', 'betbuilder', 'market', marketId, 'odd', oddId], sessionData)
  }

  shareBet(sessionData: SessionData, request: PrepareBetsRequest): Promise<PreparedBets> {
    return postJson<PrepareBetsRequest, PreparedBets>(
      this.httpClient,
      ['api', 'bet', 'preparation'],
      request,
      sessionData
    )
  }

  getSharedBet(sessionData: SessionData, referenceId: string): Promise<PreparedBets> {
    return getJson<PreparedBets>(this.httpClient, ['api', 'bet', 'preparation', referenceId], sessionData)
  }

  getTopWinners(sessionData: SessionData, startDate: string, endDate: string, startIndex: number = 0, pageSize: number = 10): Promise<TopWinnerDTO[]> {
    const query = {
      startDate: startDate,
      endDate: endDate
    } as any

    return getJson<TopWinnerDTO[]>(this.httpClient, ['api', 'winners', 'top', startIndex, pageSize], sessionData, query)
  }
}
