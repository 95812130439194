import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { HotjarSettingsDTO } from '@arland-bmnext/webapps-api-data'
import { useUser } from '../lib/user'
import { UserType } from '@arland-bmnext/api-data'

const useHotjar = (
  hotjarSettings: HotjarSettingsDTO,
  consentRequired: boolean,
  performanceCookiesAccepted: boolean
) => {
  const { user } = useUser()

  useEffect(() => {
    if (
      hotjarSettings?.enabled &&
      (!consentRequired || performanceCookiesAccepted) &&
      user != null &&
      user.type === UserType.Customer
    ) {
      if (!hotjar.initialized()) hotjar.initialize(parseInt(hotjarSettings.siteId), parseInt(hotjarSettings.versionNo))
      hotjar.identify(user?.id.toString(), {
        firstName: user?.profile?.firstName,
        lastName: user?.profile?.lastName,
        logonName: user?.logonName,
      })
    }
  }, [hotjarSettings, consentRequired, performanceCookiesAccepted, user])
}

export default useHotjar
