import { Sport } from '@arland-bmnext/api-data'
import { HttpClient, getJson, SessionData } from '../core'

export class SportsClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getSportById(sessionData: SessionData, sportId: number): Promise<Sport> {
    const sport = await getJson<Sport>(this.httpClient, ['api', 'sport', sportId], sessionData)
    return sport
  }

  public async getAllSports(sessionData: SessionData, languageId: number = 1): Promise<Sport[]> {
    const sports = await getJson<Sport[]>(this.httpClient, ['api', 'sport'], sessionData)
    return sports
  }

  public async getBettableSports(
    sessionData: SessionData,
    startDate: string,
    endDate: string,
    languageId: number = 1
  ): Promise<Sport[]> {
    const sports = await getJson<Sport[]>(
      this.httpClient,
      ['api', 'sport', 'bettable', startDate, endDate, languageId],
      sessionData
    )
    return sports
  }
}
