import { ErrorStatus } from "./httpclient"

function isSuccess(httpStatus: number): boolean {
  return httpStatus >= 200 && httpStatus < 300
}

export function toErrorStatus(httpStatus: number): ErrorStatus | undefined {
  if (isSuccess(httpStatus)) return undefined

  if (httpStatus == 404) return 'not-found'
  if (httpStatus == 401) return 'unauthorized'
  if (httpStatus == 403) return 'unauthorized'
  if (httpStatus == 600) return 'session-timeout'

  return 'api-error'
}

